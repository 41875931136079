import React, { useContext } from 'react';
import { Gift } from '../../svg/Gift/Gift';
import { debugLog } from '../../../util/logUtils';
import Play from '../../svg/IconV2-2/Play';
import PlayQuad from '../../svg/quadrado/Play';
import popupStore from '../context/popupContext/popupStore';
import ThemeWrapper, { QUADRADO, VENUS } from '../factory/ThemeWrapper';
import buttonDecorators from '../hybrid/cards/decorators/ButtonDecorators';
import LinkWrapper from '../util/LinkWrapper';

const ICON_MAP = {
	Play: <Play />
},
	ICON_MAP_QUADRADO = {
		Play: <PlayQuad />,
		Gift: <Gift />
	},
	iconMapper = (icon = '', theme = VENUS) => {
		debugLog('iconMapper:', icon, theme);
		if (theme === QUADRADO) {
			return ICON_MAP_QUADRADO[icon];
		} else {
			return ICON_MAP[icon];
		}
	},
	ButtonView = (props = {}) => {
		const { buttonData = {}, buttonMeta = {}, onClick = '', noLink = false } = props,
			{ label = '', icon = '', url = {} } = buttonData,
			style =  props.style || props.className || '',
			iconJSX = iconMapper(icon, props.theme),
			isPlanActive = props.isPlanActive || false,
			iconMeta = buttonMeta.icon || {},
			{ sizeClass = 'btn-lg' } = props,
			hasIcon = !(!(iconMeta.canShow && iconJSX)),
			{ customAction = '' } = url,
			buttonStyle = buttonMeta.style || '';

		let className = `btn vt-btn${hasIcon ? ' icon-btn' : ''}${noLink ? ' no-link' : ''}`;

		if (style) {
			className = `${className} ${style} ${buttonStyle}`;
		} else {
			className = `${className} ${sizeClass || 'btn-lg'} vt-btn-${(props.primary && !isPlanActive)  ? 'primary' : 'secondary'} ${buttonStyle}`;
		}
		//context
		const { activePoup, setActivePopup } = useContext(popupStore);
		//functions
		const handleClick = (evt = {}) => {
			if (onClick) {
				onClick(evt);
			} else {
				if (customAction) {
					if (customAction !== activePoup) {
						setActivePopup(customAction);
					}
				}
			}
		}
		return (<>
			{url && url.url ? <LinkWrapper action={url} title={label} className={className} >
				{iconMeta.canShow && iconJSX}{label}
			</LinkWrapper> : <div title={label} className={className}
				onClick={handleClick}
			>
				{iconMeta.canShow && iconJSX}{label}
			</div>}
		</>);
	};

export default ThemeWrapper(buttonDecorators(ButtonView));