import React from 'react';
import FooterRequest from '../../../js/request/FooterRequest';
import PrivacyCheck from '../../PrivacyCheck';
import CodeSnippetsWidget from '../widgets/CodeSnippetsWidget';
import FooterLayoutFactory from './layout/FooterLayoutFactory';
import PageLevelPopup from '../widgets/PageLevelPopup';
import { canShowPageLevelPopup } from '../../../../util/popupUtil';
import FooterStore from './FooterStore';

const HybridFooter = (props) => {

	// LOADING FROM PROPS DATA
	const data = props.footerData || {},
		widgets = data.widgets || [],
		layout = props.footerLayout || 1,
		privacyCheck = widgets.filter(widget => widget.type === "PrivacyCheck"),
		codeSnippets = widgets.filter(widget => widget.type === "CodeSnippetsWidget"),
		pageLevelPopup = widgets.filter(widget => widget.type === "PageLevelPopup"),
		location = window.location || {},
		pathname = location.pathname || '',
		hidden = props.hidden || false;


	return (
		<> 
			{!hidden && <FooterLayoutFactory data={widgets} layout={layout}/>}
			{!props.termsConsentCheck && privacyCheck[0] && <PrivacyCheck widget={privacyCheck[0]} />}

			{codeSnippets.length > 0 && <CodeSnippetsWidget widget={codeSnippets[0]} />}
			{(pageLevelPopup.length > 0 && canShowPageLevelPopup(pathname)) && <PageLevelPopup widget={pageLevelPopup[0]} />}
		</>);
}

export default FooterRequest(HybridFooter);