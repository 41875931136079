import React, { useState } from 'react';
import { debugLog } from '../../../util/logUtils';
import VideoPlayerStore from './VideoPlayerStore';

const VideoPlayerProvider = ({ children }) => {

	const [playerActive, setPlayerActive] = useState(false),
		[commentBox, setCommentBox] = useState(false),
		[videoKey, setVideoKey] = useState(''),
		[videoDetails, setVideoDetails] = useState({
			videoKey: videoKey,
            videoTargetid: "",
            videoPreload: false,
            videoPageTakeOver: false,
            limitedViewing: false,
		}),
		[inPageTakeOver, setInPageTakeOver] = useState(false),
		[ closeClickCount, setCloseClickCount ] = useState(0);

	return (
		<VideoPlayerStore.Provider
			value={{
				closeClickCount,
				onCloseClickCount: () => {
					setCloseClickCount(closeClickCount + 1);
				},
				inPageTakeOver,
				setInPageTakeOver,
				playerActive,
				setPlayerActive,
				commentBox,
				setCommentBox,
				videoDetails,
				setVideoDetails: (videoDetailsL = {}) => {
					const videoKeyL = videoDetailsL.video_key || "",
               			videoId = videoDetailsL.video_id || 0;

					if (videoKeyL !== videoKey) {
						setVideoKey(videoKeyL);
						setVideoDetails({
							videoId,
							videoKey: videoKeyL,
							videoTargetid: videoDetailsL.video_targetid || "",
							videoPreload: videoDetailsL.video_preload || false,
							videoPageTakeOver: videoDetailsL.video_pageTakeOver || false,
							limitedViewing: videoDetailsL.video_limitedViewing || false,
							resume: videoDetailsL.video_resume || false,
							forceBegin: videoDetailsL.video_forceBegin || false,
							exitOnComplete: videoDetailsL.exitOnComplete,
							startMuted: !(!videoDetailsL.startMuted)
						});
					}
				}
			}}>
			{children}
		</VideoPlayerStore.Provider>
	)
}

export default VideoPlayerProvider