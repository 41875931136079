import React, { Component } from 'react';

import './../scss/SearchForm.scss';

import { generateRandomID, getAPIData } from './../../util/lib';
import { showNotification } from './../../util/notification';
import { pixelTrack, gaEvent } from '../../util/trackerUtil';
import { ga4Event } from './trackers/GA4Tracker';
import { debugLog } from '../../util/logUtils';
import Search from '../svg/Search/Search';

const DEFAULT_SEARCH_LABEL = 'Search',
	MOST_RECENT = 'Most Recent',
	MOST_WATCHED = 'Most Watched',
	trackSearch = () => {
		const trackerEvent = DEFAULT_SEARCH_LABEL;

		pixelTrack(trackerEvent);
		gaEvent(trackerEvent);
	},
	SEARCH_LIST = 'search-list';

class SearchForm extends Component {
	constructor(props) {
		const wData = props.data || {},
			data = wData.data || {},
			qry = data.qry || '',
			labels = data.labels || {},
			searchLabel = labels.search_label_small || DEFAULT_SEARCH_LABEL,
			randomID = generateRandomID(6);

		//debugLog(props);

		super(props);

		if (qry) {
			trackSearch();
		}

		this.state = {
			prevvalue: qry,
			value: qry,
			searchLabelSmall: searchLabel,
			searchLabelBig: labels.search_label_large || searchLabel,
			searchLabel,
			searchId: `vtn-src-bx-${randomID}`,
			searchListId: `vtn-${SEARCH_LIST}-${randomID}`,
			searchSuggestions: [],
		};

		this.searchTextChange = this.searchTextChange.bind(this);
		this.searchFormSubmit = this.searchFormSubmit.bind(this);
		this.suggestionClick = this.suggestionClick.bind(this);
		this.checkSize = this.checkSize.bind(this);
	}
	searchTextChange(event = {}) {
		const target = event.target || {},
			value = target.value || '';


		this.setState({ value }, () => {
			const searchSuggestions = this.state.searchSuggestions || [],
				searchValue = this.state.value;

			if (searchSuggestions.indexOf(searchValue) >= 0) {
				ga4Event('search', { search_term: searchValue });
				window.location.href = '/search?qry=' + encodeURIComponent(searchValue);
			} else {
				this.searchSuggestionsRequest();
			}
		});

	}

	searchSuggestionsRequest() {
		const wData = this.props.data || {},
			data = wData.data || {},
			searchSuggestionsURLObj = data.searchSuggestionsURL || {},
			searchSuggestionsURL = searchSuggestionsURLObj.url || '',
			qry = this.state.value || '';

		if (searchSuggestionsURL && qry) {
			getAPIData(searchSuggestionsURL, { qry }, (response = {}) => {
				const responseData = response.data || {},
					widgetsRaw = responseData.widgets || [],
					widgets = Array.isArray(widgetsRaw) ? widgetsRaw : [],
					widget = widgets.length ? widgets[0] || {} : {},
					wData = widget.data || {},
					data = wData.data || [],
					searchSuggestions = Array.isArray(data) ? data : [];

				debugLog(widget);

				this.setState({ searchSuggestions });
			});
		} else {
			this.setState({ searchSuggestions: [] });
		}
	}

	searchFormSubmit(event) {
		const state = this.state,
			searchValue = state.value,
			prevValue = state.prevvalue,
			wData = this.props.data || {},
			data = wData.data || {},
			labels = data.labels || {},
			searchTextMissing = labels.search_text_missing || "Search is empty!";

		this.setState({
			prevvalue: state.value
		}, () => {
			if (searchValue === "") {
				showNotification({ text: searchTextMissing });
			} else if (searchValue === prevValue) { // compare previous state value with current state value
				event.preventDefault();
			} else {
				ga4Event('search', { search_term: searchValue });
				window.location.href = '/search?qry=' + encodeURIComponent(searchValue);
			}
		});

		event.preventDefault();
	}

	suggestionClick(event) {
		this.setState({
			value: event.target.getAttribute('value')
		}, () => {
			window.$('#sf').trigger('submit')
		});
	}

	datalistOnSelect(event) {
		debugLog(event)
	}

	checkSize() {
		const state = this.state,
			searchId = state.searchId,
			searchBox = document.querySelector('#' + searchId) || {},
			searchWidth = searchBox.offsetWidth || 0,
			searchLabel = (searchWidth <= 0 || searchWidth >= 255) ? state.searchLabelBig : state.searchLabelSmall

		debugLog(searchWidth);

		this.setState({ searchLabel });
	}

	componentWillMount() {
		window.removeEventListener('resize', this.checkSize);
	}

	componentDidMount() {
		this.checkSize();
		window.addEventListener('resize', this.checkSize);
	}

	render() {
		const props = this.props,
			state = this.state,
			propsData = props.data || {},
			data = propsData.data || {},
			id = props.id,
			title = data.title || null,
			containerYN = props.inline ? '' : ' container',
			inPage = props.inline ? '' : ' in-page',
			actionURL = data.actionURL || {},
			action = actionURL.path,
			searchSuggestions = state.searchSuggestions,
			suggestions = data.suggestions || {},
			{ most_recent: mostRecent = [], most_watched: mostWatched = [] } = suggestions;

		debugLog("search:", title);

		return (
			<div id={id} className={'v-widget search-form' + containerYN}>

				{title &&
					<div className='widget-title'>
						<h2>{title}</h2>
					</div>
				}
				<form id={"sf"} action={action} className='form-inline' onSubmit={this.searchFormSubmit}>
					<div className={'input-group' + inPage}>
						<input
							className="form-control search-box"
							id={state.searchId}
							placeholder={state.searchLabel}
							type="search"
							aria-label="Search"
							name='qry'
							value={state.value}
							onChange={this.searchTextChange}
							autoComplete={"off"}
							list={state.searchListId}
						/>
						{<datalist className={SEARCH_LIST} id={state.searchListId}>
							{searchSuggestions.map((suggestion = '', index = 0) => {
								return (<option key={'op-' + index} value={String(suggestion)} />);
							})}
						</datalist>}
						<button className="btn search-button" type="submit">
							<Search />
							{/* <i className="vt vt-search" aria-hidden="true"></i> */}
						</button>
						<br /><br />
					</div>
				</form>
				{!this.state.value &&
					<div className={"container-fluid"}>
						<div className={"suggestions row"}  >
							{mostRecent && Object.keys(mostRecent).length > 0 &&
								<div className={"col-6"}>
									<div className="widget-title">
										<h2> {MOST_RECENT}</h2>
									</div>
									{mostRecent.map((item, index) =>
										<li value={item} key={index} onClick={this.suggestionClick}>
											{item}
										</li>
									)}
								</div>
							}

							{mostWatched && Object.keys(mostWatched).length > 0 &&
								<div className="col-6">
									<div className="widget-title">
										<h2> {MOST_WATCHED}</h2>
									</div>
									{mostWatched.map((item, index) =>
										<li value={item} key={index} onClick={this.suggestionClick}>
											{item}
										</li>
									)}
								</div>
							}
						</div>
					</div>
				}
			</div>
		);
	}
}

export default SearchForm;
