import React from 'react';
import { getThemeID } from '../../../util/lib';

export const VENUS = 'venus';
export const JUPITER = 'jupiter';
export const EARTH = 'earth';
export const MARS = 'mars';
export const QUADRADO = 'quadrado';

const ThemeWrapper = (Comp) => {
	return (props = {}) => {
		let theme = '';
		const themeID = getThemeID();

		switch (themeID) {
			case (3):
				theme = JUPITER;
				break;
			case (2):
				theme = EARTH;
				break;
			case (4):
				theme = MARS;
				break;
			case (5):
				theme = QUADRADO;
				break;
			default:
				theme = VENUS;
		}

		return (themeID ? <Comp {...{ themeID, theme, ...props }} /> : null);
	};

}

export default ThemeWrapper;