import React from 'react';
import { Helmet } from 'react-helmet';

import { rgbHex2rgbaHex, changeColorLightness } from '../../../util/colorUtils.js';

const SiteColorConfigurer = ({settingsData = {}}) => {
	const headerTextColor = settingsData.header_text_color || settingsData.primary_color,
		surfaceEmphasisHigh = settingsData.vtss_on_surface_emphasis_high || settingsData.primary_color,
		surfaceEmphasisMedium = settingsData.vtss_on_surface_emphasis_medium || settingsData.secondary_color || surfaceEmphasisHigh,
		brandPrimary = settingsData.vtss_brand_primary || settingsData.accent_color,
		brandSecondary = settingsData.vtss_brand_secondary || '',
		brandSecondaryColor = brandSecondary || settingsData.button_color || brandPrimary,
		buttonTextColor = settingsData.vtss_on_brand_emphasis_high || settingsData.button_text_color || surfaceEmphasisHigh,
		backgroundBaseColor = settingsData.vtss_background_base || settingsData.background_color,
		surfacePrimary = settingsData.vtss_surface_primary || settingsData.header_color,
		surfaceSecondary = settingsData.vtss_surface_secondary || settingsData.vtss_surface_primary || settingsData.header_color,
		vhlHeaderColor = settingsData.vhl_header_color || brandPrimary,
		warningColor = settingsData.vtss_warning_color || brandPrimary,
		socialLoginFacebookBtnBgColor = settingsData.facebook_login_button_color || '4866AB',
		socialLoginFacebookBtnTxtColot = settingsData.facebook_login_text_color || settingsData.primary_color || 'ffffff',
		socialLoginGoogleBtnBgColor = settingsData.google_login_button_color || 'dd4b39',
		socialLoginGoogleBtnTxtColot = settingsData.google_login_text_color || settingsData.primary_color || 'ffffff';

	return (<>
		<style>{
			//
			`:root {
				--accent-color: #${brandPrimary};
				--accent-color-a70: #${rgbHex2rgbaHex(brandPrimary, 0.7)};
				--accent-color-a50: #${rgbHex2rgbaHex(brandPrimary, 0.5)};
				--accent-color-d15: #${changeColorLightness(brandPrimary, -15)};
				--accent-color-d30: #${changeColorLightness(brandPrimary, -30)};
				--accent-color-l25: #${changeColorLightness(brandPrimary, 25)};
				--vhl-header-color: #${rgbHex2rgbaHex(vhlHeaderColor, 1)};
				--brand-secondary: #${brandSecondaryColor};
				--botton-color: #${brandSecondaryColor};
				--botton-color-a70: #${rgbHex2rgbaHex(brandSecondaryColor, 0.7)};
				--botton-hover-color: #${changeColorLightness(brandSecondaryColor, -15)};
				--botton-disabled-color: #${changeColorLightness(brandSecondaryColor, -30)};
				--botton-text-color: #${buttonTextColor};

				--header-text-color: #${headerTextColor || surfaceEmphasisHigh};

				--background: #${backgroundBaseColor};
				--background-a80: #${rgbHex2rgbaHex(backgroundBaseColor, 0.8)};
				--background-a70: #${rgbHex2rgbaHex(backgroundBaseColor, 0.7)};
				--background-a50: #${rgbHex2rgbaHex(backgroundBaseColor, 0.5)};
				--background-a50: #${rgbHex2rgbaHex(backgroundBaseColor, 0.45)};
				--background-a20: #${rgbHex2rgbaHex(backgroundBaseColor, 0.2)};
				--background-a00: #${rgbHex2rgbaHex(backgroundBaseColor, 0)};
				--background-l17: #${changeColorLightness(backgroundBaseColor, 17)};

				--surface-primary: #${surfacePrimary};
				--surface-secondary: #${surfaceSecondary};
				--surface-secondary-a20: #${rgbHex2rgbaHex(surfaceSecondary, 0.2)};


				--text-primary: #${surfaceEmphasisHigh};
				--text-primary-a70: #${rgbHex2rgbaHex(surfaceEmphasisHigh, 0.7)};
				--text-primary-a50: #${rgbHex2rgbaHex(surfaceEmphasisHigh, 0.5)};
				--text-primary-a10: #${rgbHex2rgbaHex(surfaceEmphasisHigh, 0.1)};
				--text-primary-a25: #${rgbHex2rgbaHex(surfaceEmphasisHigh, 0.2)};
				--text-primary-d50: #${changeColorLightness(surfaceEmphasisHigh, -50)};

				--text-secondary: #${surfaceEmphasisMedium};
				--text-secondary-a50: #${rgbHex2rgbaHex(surfaceEmphasisMedium, 0.5)};
				--text-secondary-a75: #${rgbHex2rgbaHex(surfaceEmphasisMedium, 0.75)};
				--text-secondary-d15: #${changeColorLightness(surfaceEmphasisMedium, -15)};
				--text-secondary-d30: #${changeColorLightness(surfaceEmphasisMedium, -30)};
				--text-secondary-l25: #${changeColorLightness(surfaceEmphasisMedium, 25)};

				--header-color: #${surfacePrimary};
				--background-image: url(${settingsData.background_image});
				--background-image-sm: url(${settingsData.background_image_small || settingsData.background_image});
				--facebook-login-button-color: #${socialLoginFacebookBtnBgColor};
				--facebook-login-text-color: #${socialLoginFacebookBtnTxtColot};
				--google-login-button-color: #${socialLoginGoogleBtnBgColor};
				--google-login-text-color: #${socialLoginGoogleBtnTxtColot};
				--warning-color: #${warningColor};
				--warning-color-a30: #${rgbHex2rgbaHex(warningColor, 0.3)};
		`}</style>
		<Helmet>
			{brandPrimary && <meta name="theme-color" content={"#" + brandPrimary} />}
		</Helmet>
	</>);
}

export default SiteColorConfigurer
