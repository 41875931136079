import React from 'react';
import Loadable from '@react-loadable/revised';

const widgetFactoryMap = {
	OrderListing: {
		type: Loadable({ loader: () => import('../OrderListing'), loading: () => <div></div> }),
		idPrefix: "odls-"
	},
	ShopPayment: {
		type: Loadable({ loader: () => import('../ShopPayment'), loading: () => <div></div> }),
		idPrefix: "shpmt-"
	},
	Error: {
		type: Loadable({ loader: () => import('../Error'), loading: () => <div></div> }),
		idPrefix: "er-"
	},
	ShopCheckoutForm: {
		type: Loadable({ loader: () => import('../ShopCheckoutForm'), loading: () => <div></div> }),
		idPrefix: "scf-"
	},
	ProductDetails: {
		type: Loadable({ loader: () => import('../ProductDetails'), loading: () => <div></div> }),
		idPrefix: "prdt-"
	},
	ProductGridListing: {
		type: Loadable({ loader: () => import('../ProductGridListing'), loading: () => <div></div> }),
		idPrefix: "pgl-"
	},
	HybridDetailWidget: {
		type: Loadable({ loader: () => import('../hybrid/widgets/DetailsWidget'), loading: () => <div></div> }),
		idPrefix: "md_",
	},
	LoginForm2: {
		type: Loadable({ loader: () => import('../Login'), loading: () => <div></div> }),
		idPrefix: "l_",
		passFullProps: true
	},
	LoginForm: {
		type: Loadable({ loader: () => import('../LoginForm'), loading: () => <div></div> }),
		idPrefix: "lf_",
		passFullProps: true
	},
	RegisterForm: {
		type: Loadable({ loader: () => import('../RegisterForm'), loading: () => <div></div> }),
		idPrefix: "lf_"
	},
	//deprecated
	// PlanListing: {
	// 	type: Loadable({ loader: () => import('../PlanListing'), loading: () => <div></div> }),
	// 	idPrefix: "lf_",
	// 	passFullProps: true
	// },
	PlanListing: {
		type: Loadable({ loader: () => import('../payment/PlanListing/PlanListingV2'), loading: () => <div></div> }),
		idPrefix: "PLV2_",
		passFullProps: true
	},
	UserProfile: {
		type: Loadable({ loader: () => import('../UserProfile'), loading: () => <div></div> }),
		idPrefix: "up-"
	},
	SearchForm: {
		type: Loadable({ loader: () => import('../SearchForm'), loading: () => <div></div> }),
		idPrefix: "schfrm-",
		onDataChange: true
	},
	Payment: {
		type: Loadable({ loader: () => import('../payment/Payment'), loading: () => <div></div> }),
		idPrefix: "pmt-"
	},
	CheckoutPayment: {
		type: Loadable({ loader: () => import('../payment/CheckoutPayment'), loading: () => <div></div> }),
		idPrefix: "co-pmt-"
	},
	MultiPlanPayment: {
		type: Loadable({ loader: () => import('../payment/MultiPlanPayment'), loading: () => <div></div> }),
		idPrefix: "mpp-"
	},
	PaymentConfirmation: {
		type: Loadable({ loader: () => import('../payment/PaymentConfirmation'), loading: () => <div></div> }),
		idPrefix: "pcf-"
	},
	DeviceConnect: {
		type: Loadable({ loader: () => import('../DeviceConnect'), loading: () => <div></div> }),
		idPrefix: "divc-"
	},
	SupportForm: {
		type: Loadable({ loader: () => import('../SupportForm'), loading: () => <div></div> }),
		idPrefix: "spfrm-"
	},
	MessageWidget: {
		type: Loadable({ loader: () => import('../MessageWidget'), loading: () => <div></div> }),
		idPrefix: "mswg-",
		passtype: true
	},
	NotFound: {
		type: Loadable({ loader: () => import('../MessageWidget'), loading: () => <div></div> }),
		idPrefix: "mswg-",
		passtype: true
	},
	FullPageMessageWidget: {
		type: Loadable({ loader: () => import('../FullPageMessageWidget'), loading: () => <div></div> }),
		idPrefix: "fpmw-"
	},
	FilterHorizontalWidget: {
		type: Loadable({ loader: () => import('../FilterHorizontalWidget'), loading: () => <div></div> }),
		idPrefix: "flhl-",
		onDataChange: true
	},
	HorizontalAdBanner: {
		type: Loadable({ loader: () => import('../adbanner/HorizontalAdBanner'), loading: () => <div></div> }),
		idPrefix: "hab-"
	},
	VideoVerticalListing: {
		type: Loadable({ loader: () => import('../TabbedVerticalVideoListing'), loading: () => <div></div> }),
		idPrefix: "tvvl-"
	},
	HybridGridListing: {
		type: Loadable({ loader: () => import('../hybrid/widgets/HybridGridListing'), loading: () => <div></div> }),
		idPrefix: "tvvl-"
	},
	AppLinksHorizontalListing: {
		type: Loadable({ loader: () => import('../AppLinksHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "alhl-"
	},
	ShowDetails: {
		type: Loadable({ loader: () => import('../ShowDetails'), loading: () => <div></div> }),
		idPrefix: "shwd-"
	},
	RSSWallApp: {
		type: Loadable({ loader: () => import('../3rdPartyWidgets/RSSWallApp'), loading: () => <div></div> }),
		idPrefix: "rwp-"
	},
	TabWidget: {
		type: Loadable({ loader: () => import('../TabWidget'), loading: () => <div></div> }),
		idPrefix: "tab-"
	},
	TicketVerticalListing: {
		type: Loadable({ loader: () => import('../TicketVerticalListing'), loading: () => <div></div> }),
		idPrefix: 'vertical-ticket-listing-'
	},
	HybridPosterWidget: {
		type: Loadable({ loader: () => import('../hybrid/widgets/HybridPosterWidget'), loading: () => <div></div> }),
		idPrefix: "hpw-",
	},
	HybridCarousel: {
		type: Loadable({ loader: () => import('../hybrid/widgets/HybridCarousel'), loading: () => <div></div> }),
		idPrefix: "hc-"
	},
	HybridHorizontalListing: {
		type: Loadable({ loader: () => import('../hybrid/widgets/HybridHorizontalListing'), loading: () => <div></div> }),
		idPrefix: "hhl_"
	},
	HybridVerticalListing: {
		type: Loadable({ loader: () => import('../hybrid/widgets/HybridVerticalListing'), loading: () => <div></div> }),
		idPrefix: "hvl_"
	},
	HybridDetails: {
		type: Loadable({ loader: () => import('../hybrid/cards/HybridDetails'), loading: () => <div></div> }),
		idPrefix: "hdl_"
	},
	HybridForm: {
		type: Loadable({ loader: () => import('../hybrid/forms/HybridForm'), loading: () => <div></div> }),
		idPrefix: "hlf_",
		handlers: true
	},
	HybridTab: {
		type: Loadable({ loader: () => import('../hybrid/widgets/HybridTab'), loading: () => <div></div> }),
		idPrefix: "hlf_",
		handlers: true
	},
	HybridLinkWidget: {
		type: Loadable({ loader: () => import('../hybrid/footer/HybridLinkWidget'), loading: () => <div></div> }),
		idPrefix: "hlw_",
	},
	SocialShare: {
		type: Loadable({ loader: () => import('../social/SocialShare'), loading: () => <div></div> }),
		idPrefix: "ss_",
	},
	HeroWidget: {
		type: Loadable({ loader: () => import('../hybrid/widgets/HeroWidget'), loading: () => <div></div> }),
		idPrefix: "hwc",
	},
	ParagraphTextWidget: {
		type: Loadable({ loader: () => import('../hybrid/widgets/ParagraphTextWidget'), loading: () => <div></div> }),
		idPrefix: "ptw",
	},
	FlexWidget: {
		type: Loadable({ loader: () => import('../hybrid/widgets/FlexWidget'), loading: () => <div></div> }),
		idPrefix: "flxw",
	},
	HybridScrollWidget: {
		type: Loadable({ loader: () => import('../hybrid/widgets/HybridScrollWidget'), loading: () => <div></div> }),
		idPrefix: "hsw",
	},
	ReviewsAndComments: {
		type: Loadable({ loader: () => import('../ReviewsAndComments/ReviewsAndComments'), loading: () => <div></div> }),
		idPrefix: "rnc",
	},
	CodeSnippetWidget: {
		type: Loadable({ loader: () => import('../hybrid/widgets/CodeSnippetWidget'), loading: () => <div></div> }),
		idPrefix: "html",
	},
	GiftCardMessage: {
		type: Loadable({ loader: () => import('../MessageWidgets/GiftCardMessage'), loading: () => <div></div> }),
		idPrefix: "gfcm",
	},
	EpgWidgetV2: {
		type: Loadable({ loader: () => import('../epgV2/EpgWidgetV2'), loading: () => <div></div> }),
		idPrefix: "epgw2",
	},
	AddCardStripeWrapper: {
		type: Loadable({ loader: () => import('../payment/AddCardWrapper/AddCardStripeWrapper'), loading: () => <div></div> }),
		idPrefix: "acsw",
	},
	DeleteMyAccount: {
		type: Loadable({ loader: () => import('../DeleteMyAccount/DeleteMyAccount.js'), loading: () => <div></div> }),
		idPrefix: "dmacc",
	},
	PageLevelPopup: {
		type: Loadable({ loader: () => import('../hybrid/widgets/PageLevelPopup'), loading: () => <div></div> }),
		idPrefix: "plpw",
	},
	LiblynxIPAuth: {
		type: Loadable({ loader: () => import('../AuthHelpers/LiblynxIPAuth'), loading: () => <div></div> }),
		idPrefix: "llyx-ip-auth",
	},
	AutoLogin: {
		type: Loadable({ loader: () => import('../AuthHelpers/AutoLogin'), loading: () => <div></div> }),
		idPrefix: "al",
	},
	Table: {
		type: Loadable({ loader: () => import('../Table/Table'), loading: () => <div></div> }),
		idPrefix: "table-w",
	}
};
export default widgetFactoryMap;